import {Icon as CIcon} from '@elanco/component-library-v2'
import {useQuery} from 'react-query'
import {useRouter} from 'next/router'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import Image from 'next/image'
import {groupReminders} from '@/utils/YPAY/reminders'
import type {Reminder} from '@/fetchers/fetchReminders'
import {fetchPetReminders} from '@/fetchers/fetchReminders'
import {RefillButton} from '@/_new-code/products/your-pet-and-you/blocks/dashboard-page/module-my-reminders/refill-button'
import type {SmallTextContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/small-text'
import type {IconContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/icon'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {LoadingSpinner} from '@/_new-code/products/flexible-web-toolkit/components/loading-spinner'
import {loadImageFromKontentAI} from '@/imageLoader'

type Frequency = Record<number, string>

export type ModuleTableLayoutContentItem = IContentItem<{
	heading: Elements.TextElement
	columnHeadings: Elements.LinkedItemsElement<SmallTextContentItem>
	frequencyDailyText: Elements.TextElement
	frequencyMonthlyText: Elements.TextElement
	frequencyBimonthlyText: Elements.TextElement
	frequency8MonthsText: Elements.TextElement
	noRemindersText: Elements.TextElement
	editIcon: Elements.LinkedItemsElement<IconContentItem>
}>

export const ModuleTableLayoutBlock: Block<ModuleTableLayoutContentItem> = ({
	block,
	globalConfig,
}) => {
	const {locale = '', asPath, ...router} = useRouter()

	const {isLoading, data: reminders} = useQuery<Reminder[], Error>({
		queryKey: ['reminders'],
		queryFn: () => fetchPetReminders(locale),
		staleTime: Infinity,
		cacheTime: 15 * (60 * 1000),
	})

	if (isLoading) {
		return (
			<div className="text-center">
				<LoadingSpinner theme="green" />
			</div>
		)
	}

	const frequency: Frequency = {
		1: block.elements.frequencyDailyText,
		30: block.elements.frequencyMonthlyText,
		60: block.elements.frequencyBimonthlyText,
		240: block.elements.frequency8MonthsText,
	}

	const groupedMedicationsArray = groupReminders(reminders || [])

	const sortedReminders = groupedMedicationsArray.sort(
		(a, b) => a.pet?.name.localeCompare(b.pet?.name ?? '') ?? 0
	)

	return (
		<div className="container-wide">
			{block.elements.heading ? (
				<h2 className="mb-2 text-2xl font-semibold text-midnight-blue">
					{block.elements.heading}
				</h2>
			) : null}
			<table className="block w-full md:table">
				<thead>
					<tr className="absolute left-[-9999px] top-[-9999px] block border-light-grey md:static md:left-auto md:top-auto md:table-row md:border-b">
						{block.elements.columnHeadings.map((col) => (
							<th
								className="border-0 px-4 py-3 text-left italic first-of-type:pl-0"
								key={col.system.id}
							>
								{col.elements.content}
							</th>
						))}
					</tr>
				</thead>
				<tbody className="block border-t-2 border-light-grey md:table-row-group md:border-0">
					{sortedReminders.length > 0 ? (
						<>
							{sortedReminders.map((r) => (
								<tr
									className="relative block border-b border-light-grey !bg-white px-4 py-2 md:table-row"
									key={r.id}
								>
									<td className="grid border-r-0 px-4 py-2 text-left first-letter:uppercase md:table-cell md:pl-0">
										<span className="font-bold italic text-midnight-blue  md:hidden">
											{
												block.elements.columnHeadings[0]
													?.elements.content
											}
										</span>
										<span className="text-[#333]">
											{r.pet?.name}
										</span>
									</td>
									<td className="grid border-r-0 px-4 py-2 text-left md:table-cell">
										<span className="font-bold italic text-midnight-blue  md:hidden">
											{
												block.elements.columnHeadings[1]
													?.elements.content
											}
										</span>
										<span className="text-[#333]">
											{r.marketingBrand?.name}
										</span>
									</td>
									<td className="grid border-r-0 px-4 py-2 text-left md:table-cell">
										<span className="font-bold italic text-midnight-blue  md:hidden">
											{
												block.elements.columnHeadings[2]
													?.elements.content
											}
										</span>
										<span className="text-[#333]">
											{
												frequency[
													r.marketingBrand
														?.dosingFrequency ?? -1
												]
											}
										</span>
									</td>
									<td className="grid border-r-0 px-4 py-2 text-left md:table-cell">
										<span className="font-bold italic text-midnight-blue  md:hidden">
											{
												block.elements.columnHeadings[3]
													?.elements.content
											}
										</span>
										<span className="text-[#333]">
											{r.recurringDates?.[0]}
										</span>
									</td>
									<td className="absolute right-0 top-0 border-r-0 p-4 md:static md:table-cell">
										<button
											className="flex items-center justify-end space-x-2"
											type="button"
										>
											<CIcon
												className="h-6 w-6"
												noBackground
												noBorder
											>
												{block.elements.editIcon[0] ? (
													<Image
														alt={
															block.elements
																.editIcon[0]
																.elements
																.icon[0]
																?.description ||
															''
														}
														className="h-6 w-6 object-contain"
														height={24}
														loader={
															loadImageFromKontentAI
														}
														onClick={() => {
															void router.push(
																{
																	pathname:
																		asPath,
																	query: {
																		edit: true,
																		reminder:
																			r.id,
																	},
																},
																undefined,
																{
																	locale,
																	shallow:
																		true,
																}
															)
															window.scrollTo(
																0,
																0
															)
														}}
														src={
															block.elements
																.editIcon[0]
																.elements
																.icon[0]?.url ||
															''
														}
														width={24}
													/>
												) : null}
											</CIcon>
										</button>
									</td>
									<td className="inline-flex border-r-0 px-4 py-2 md:table-cell">
										<RefillButton
											className="text-sm uppercase"
											globalConfig={globalConfig}
											id={r.marketingBrand?.id}
										/>
									</td>
								</tr>
							))}
						</>
					) : (
						<tr className="block bg-white px-4 py-2 md:table-row">
							<td className="py-10 text-center" colSpan={4}>
								{block.elements.noRemindersText}
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	)
}
